/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import moment from 'moment'
import slugify from 'slugify'
import { Link } from 'gatsby'

import { Wrapper, Title, Paragraph, Post, NewsletterCard, ContactCard } from './../../components'
import { DefaultLayout as Layout } from './../../layouts'
import { reduceSummary, extractArticlesFromPageContext, makePagination } from './utils'
import Icon from '../../components/Icon'

import styles from './styles'

type Article = {
  node: {
    first_publication_date: string,
    uid: string,
    data: {
      illustration: {
        Miniature?: {
          url: string,
        },
        url: string,
      },
      title_post: {
        text: string,
      },
      meta_description: {
        text: string,
      },
      categories: string,
    },
  },
}

type Props = {
  url: string,
  name: string,
  title: string,
  description: string,
  pageContext: {
    posts: Article[],
    numberOfPages: number,
    currentPage: number,
  },
}

type State = {
  articles: Article[],
  cursor: string,
  isLoading: boolean,
  hasMoreArticles: boolean,
}

export default class BlogTemplate extends React.Component<Props, State> {
  static defaultProps = {
    name: 'Wino Blog',
    title: 'Blog',
    description:
      "Des conseils, des idées d'experts, des histoires vraies et tout ce dont vous avez besoin concernant la vente et la gestion.",
  }

  constructor(props: Props) {
    super(props)
    const articles = extractArticlesFromPageContext(props.pageContext)
    this.state = {
      articles,
      cursor: null,
      isLoading: false,
      hasMoreArticles: true,
    }
  }

  render() {
    const { url, name, title, description, pageContext } = this.props
    const { articles } = this.state
    const firstArticles = articles.slice(0, 3)
    const lastArticles = articles.slice(3, articles.length)
    const { numberOfPages, currentPage } = pageContext

    const renderPageNumbers = makePagination(currentPage, numberOfPages).map(number => (
      <Link
        className={css(styles.pagination, number === currentPage ? styles.pageSelected : null)}
        key={number}
        id={number}
        to={`/blog${number === 1 ? '' : '/' + number}`}
      >
        {number}
      </Link>
    ))

    return (
      <Layout url={url} name={name} title={title} description={description}>
        <Wrapper>
          <div className={css(styles.container)}>
            <Title className={css(styles.title)}>Wino Blog</Title>
            <Paragraph className={css(styles.paragraph)} size="large">
              {description}
            </Paragraph>
          </div>
          <div className={css(styles.articles)}>
            {firstArticles.map(article => (
              <Post
                key={slugify(article.uid)}
                className={css(styles.article)}
                to={article.uid}
                media={
                  article.data.illustration.Miniature
                    ? article.data.illustration.Miniature.url
                    : article.data.illustration.url
                }
                meta={`${moment(article.first_publication_date).format('D MMMM Y')}`}
                categorie={article.data.categories}
                title={article.data.title_post.text}
                excerpt={reduceSummary(article.data.meta_description.text)}
              />
            ))}
          </div>
          {currentPage % 2 ? (
            // Displaying one banner one page out of two
            <NewsletterCard
              title="Inscrivez-vous à notre newsletter&nbsp;!"
              subtitle="Recevez régulièrement nos nouveaux articles sur votre boîte mail"
              inputPlaceholderText="Email"
              buttonText="S'inscrire maintenant"
              buttonLink="/demander-une-demo"
            />
          ) : (
            <ContactCard
              title="Vous souhaitez en savoir plus&nbsp;?"
              subtitle="Notre équipe est là pour répondre à toutes vos questions&nbsp;!"
              align="center"
              buttonText="Nous contacter"
              buttonLink="/contact"
            />
          )}
          <div className={css(styles.articles)}>
            {lastArticles.map(article => (
              <Post
                key={slugify(article.uid)}
                className={css(styles.article)}
                to={article.uid}
                media={
                  article.data.illustration.Miniature
                    ? article.data.illustration.Miniature.url
                    : article.data.illustration.url
                }
                meta={`${moment(article.first_publication_date).format('D MMMM Y')}`}
                categorie={article.data.categories}
                title={article.data.title_post.text}
                excerpt={reduceSummary(article.data.meta_description.text)}
              />
            ))}
          </div>

          <div className={css(styles.seeMoreContainer)}>
            {currentPage >= 2 && (
              <Link
                to={`/blog${currentPage === 2 ? '' : '/' + (currentPage - 1)}`}
                className={css(styles.paginationPrev)}
              >
                <Icon name="arrow" className={css(styles.iconPrev)} />
                Articles précédents
              </Link>
            )}
            {renderPageNumbers}
            {currentPage < numberOfPages && (
              <Link to={`/blog/${currentPage + 1}`} className={css(styles.paginationNext)}>
                Articles suivants
                <Icon name="arrow" className={css(styles.iconNext)} />
              </Link>
            )}
          </div>
        </Wrapper>
      </Layout>
    )
  }
}
