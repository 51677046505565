import moment from 'moment'

export function sortArticles(posts: any) {
  return posts.sort(
    (postA, postB) =>
      moment(postB.first_publication_date).isAfter(moment(postA.first_publication_date)) ? 1 : -1,
  )
}

export function extractArticlesFromPageContext(pageContext) {
  let articles = []
  for (let index = 0; index < pageContext.posts.length; index++) {
    articles.push(pageContext.posts[index].node)
  }
  return sortArticles(articles)
}

export function articlesFromPosts(data: any) {
  let articles = []
  if (Array.isArray(data.edges)) {
    for (let index in data.edges) {
      const edge = data.edges[index]
      const { node } = edge
      articles.push({
        first_publication_date: node._meta.firstPublicationDate,
        uid: node._meta.uid,
        data: {
          illustration: node.illustration,
          categories: node.categories,
          title_post: { text: node.title_post[0].text },
          meta_description: { text: node.meta_description[0].text },
        },
      })
    }
  }
  return sortArticles(articles)
}

export function reduceSummary(summary: ?string) {
  if (!summary) return ''
  const words = summary.replace(/<(?:.|\n)*?>/gm, '')
  if (words.split(' ').length > 30) {
    return `${words
      .split(' ')
      .slice(0, 30)
      .join(' ')} ...`
  }
  return words
}

export function makePagination(currentPage: number, numberOfPages: number) {
  if (numberOfPages < 5) {
    // Returning all pages

    return Array.from({ length: numberOfPages }).map((_, i) => i + 1)
  } else if (currentPage - 3 <= 0) {
    // Returning 5 first pages if our current page is <= 3

    return Array.from({ length: 5 }).map((_, i) => i + 1)
  } else if (currentPage + 2 > numberOfPages) {
    // Returning the last 5 pages if our current page is close to the max pages number

    return Array.from({ length: 5 })
      .map((_, i) => numberOfPages - i)
      .reverse()
  } else {
    // Returning the 2 pages before and after our current page
    const before = Array.from({ length: 2 })
      .map((_, i) => currentPage - (i + 1))
      .reverse()
    const after = Array.from({ length: 2 }).map((_, i) => currentPage + (i + 1))

    return [...before, currentPage, ...after]
  }
}
